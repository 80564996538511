import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
// material-ui
import React from "react";

import { Box, ButtonBase, IconButton, Stack, Typography } from "@mui/material";
import { userProfile } from "../../../../../types/reduxTypes";

import { LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import api from "../../../../../services/api";

// tab panel wrapper
function TabPanel({ children, value, index, ...other }: any) {
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`profile-tabpanel-${index}`}
			aria-labelledby={`profile-tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = ({
	userProfile,
	logout,
	clienteSeleccionado,
}: {
	userProfile: userProfile;
	logout: any;
	clienteSeleccionado: any;
}) => {
	const navigate = useNavigate();
	const handleLogout = async () => {
		logout();
		navigate("/");
	};
	const [lastSyncDate, setLastSyncDate] = useState(null);

	const anchorRef = useRef(null);

	const formatDate = (dateString: string | null): string => {
		if (!dateString) {
			return "No encontramos precios para el cliente";
		}
		const date = new Date(dateString);
		return `Precios actualizados al ${date.toLocaleDateString("es-AR", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
		})}`;
	};

	useEffect(() => {
		const fetchLastSyncDate = async () => {
			try {
				const date = await api.priceListServices.getLatestSyncDate(
					clienteSeleccionado
				);
				setLastSyncDate(date.result.length === 0 ? null : date.result);
			} catch (error) {
				console.error("Error al obtener la fecha de sincronización:", error);
				setLastSyncDate(null);
			}
		};

		if (userProfile && userProfile.sapCodigoCliente) {
			fetchLastSyncDate();
		}
	}, [clienteSeleccionado]);

	return (
		<Box sx={{ flexShrink: 0, ml: 0.75 }}>
			<ButtonBase
				sx={{
					p: 0.25,
					bgcolor: "transparent",
					borderRadius: 1,
					"&:hover": { bgcolor: "secondary.lighter" },
				}}
				aria-label="open profile"
				ref={anchorRef}
				aria-controls={undefined}
				aria-haspopup="true"
				onClick={() => {}}
			>
				<Stack
					direction="row"
					spacing={2}
					style={{ textAlign: "right" }}
					sx={{ p: 0.5 }}
				>
					<Typography variant="subtitle1">
						{userProfile.usu_mail}
						<br />
						<small> {formatDate(lastSyncDate)}</small>
					</Typography>
				</Stack>
			</ButtonBase>
			<IconButton size="large" color="secondary" onClick={handleLogout}>
				<LogoutOutlined />
			</IconButton>
		</Box>
	);
};

const mapState = (state: any) => {
	return {
		userProfile: state.sso.userProfile,
		clienteSeleccionado: state.sso.clienteSeleccionado,
	};
};
const mapDispatch = (dispatch: any) => {
	return {
		logout: () => dispatch.sso.logout(),
	};
};

export default connect(mapState, mapDispatch)(Profile);
