const config = {
 key: "id_material_rentabilidad",
 ListTitle: "Margen de productos",
 opcion: "rentabilidad",
 newButtonLabel: "Nuevo margen",
 eliminarTitle: `¿Desea eliminar el margen {{id}}?`,
 eliminarMessage: null,
 keyEliminar: "id_material_rentabilidad",
 service: "rentabilidadServices",
 createDisabled: false,
 initialValues: {
  sap_codigo_cliente: "",
  cli_organizacion_venta: "",
  cli_canal: "",
  sap_codigo_material: "",
  lp_precio_lista: "",
  lp_descuentos: 0,
  lp_precio_neto: 0,
 },
 abmTitle: "Margen de producto",
};

export default config;
