// ==============================|| THEME CONFIG  ||============================== //
import { variants } from "./constants/variants";

const config = window.config.config || {
  defaultPath: "/venta",
  apiKey: "f0a7346f87d8d3f0e47a0894804ad42f97e0acc998818f7264ef7c0b379535fd",
  fontFamily: `'Public Sans', sans-serif`,
  i18n: "es",
  miniDrawer: false,
  container: true,
  mode: "light",
  presetColor: "default",
  themeDirection: "ltr",
  sessionExpire: 3600000,
  sessionExpireAlert: 3590000,
  isOffline: false,
  apiBase: {
    // online: "http://localhost:3000/api/",

    online: "https://tintometricobetest.grupodisal.com.ar/api/",
  },
  forbiddenPath: "/not-authorized",
};

/**
 * Lean, tuve cambiar esta forma tuya porque no me redireccionaba a los diferentes login
 *
 * */
// export let VARIANT =
//   window.config.VARIANT ||
//   (window.location.search.endsWith("colorin")
//     ? variants.COLORIN
//     : window.location.search.endsWith("industria")
//     ? variants.INDUSTRIA
//     : variants.TERSUAVE); //.search.split("&");
// HayCOLOR.Tersuave

export let VARIANT =
  (window.location.search.endsWith("colorin") ||
    window.location.href.toLowerCase().includes("sitiocolor.colorin") ||
    window.location.origin.toLowerCase().includes("sitiocolorcolorin")
    ? variants.COLORIN
    : window.location.search.endsWith("industria") ||
      window.location.href.toLowerCase().includes("color.indulac3cero") ||
      window.location.origin.toLowerCase().includes("colorinindulac3cero") ||
      window.location.origin.toLowerCase().includes("indulac3cero")
    ? variants.INDUSTRIA
    : variants.TERSUAVE) || window.config.VARIANT;
export const setVariant = (v) => {
  VARIANT = v;
};
//  = variants.TERSUAVE; //"industria"; // 'tersuave' || 'industria' || 'colorin'
export default config;
export const drawerWidth = window.config.drawerWidth || 260;
export const drawerWidthMin = window.config.drawerWidthMin || 70;
export const twitterColor = window.config.twitterColor || "#ff0000";
export const facebookColor = window.config.facebookColor || "#3b5998";
export const linkedInColor = window.config.linkedInColor || "#0e76a8";
