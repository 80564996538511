const config = {
  key: "id",
  ListTitle: "Laboratorio",
  opcion: "laboratorio",
  newButtonLabel: "Laboratorio",
  service: "laboratorioServices",
  initialValues: {
    col_codigo: "",
    mat_codigo: "",
    id_formula: "",
    col_denom: "",
    lib_denom: "",
    tspro_denom: "",
    tbas_denom: "",
  },
};

export default config;
