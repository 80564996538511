import { AxiosInstance } from "axios";

const laboratorioServices = (instance: AxiosInstance) => {
  const list = (filters) =>
    instance
      .get(`/snapshot?${filters}`, { responseType: "arraybuffer" }) // Indicar que esperas un archivo binario
      .then((response) => {
        // Crear un Blob con los datos recibidos
        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Generar una URL para el archivo descargado
        const url = window.URL.createObjectURL(blob);

        // Crear un enlace para descargar el archivo
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "archivo.xlsx"); // Nombre del archivo
        document.body.appendChild(link);
        link.click(); // Simular el clic para descargar
        link.parentNode.removeChild(link); // Limpiar el DOM

        return response;
      })
      .catch((error) => {
        console.error("Error al descargar el archivo:", error);
        throw error;
      });
  const validate = (values) =>
    instance.post("/snapshot/precarga-validate", values);
  const save = (values) => instance.post("/snapshot/precarga-upload", values);
  const listPrecargas = () =>
    instance.get(`/snapshot/precarga`).then((response: any) => {
      const { data, ...rest } = response.data.result;
      return [data, rest];
    });
  const snapshotCompare = (
    firstPrecargaId,
    secondPrecargaId,
    sapCodigoCliente,
    codSucursal
  ) =>
    instance
      .get(
        `/snapshot/precarga-compare?firstPrecargaId=${firstPrecargaId}&secondPrecargaId=${secondPrecargaId}&sapCodigoCliente=${sapCodigoCliente}&codSucursal=${codSucursal}`
      )
      .then((response: any) => {
        return response.data.result;
      });
  const updateFormulas = (idPrecarga, values) =>
    instance.post(`/snapshot/save?precargaId=${idPrecarga}`, values);
  const scheduleUpdateFormulas = (values) =>
    instance.post(`/snapshot/schedule`, values);

  return {
    list,
    validate,
    save,
    listPrecargas,
    snapshotCompare,
    updateFormulas,
    scheduleUpdateFormulas,
  };
};
export default laboratorioServices;
