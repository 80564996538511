import React, { lazy } from "react";
// proimport React from 'react'ject import
import Loadable from "../components/Loadable";
import MainLayout from "../layout/MainLayout";

import Auditorias from "../pages/Auditorias/Routes";
import Contenido from "../pages/Contenido/Routes";
import Formulas from "../pages/Formulas/Routes";
import FormulasBase from "../pages/FormulasBase/Routes";
import FormulasPigmentos from "../pages/FormulasPigmentos/Routes";
import Libros from "../pages/Libros/Routes";
import MaterialType from "../pages/MaterialType/Routes";
import Materiales from "../pages/Materiales/Routes";
import Opciones from "../pages/Opciones/Routes";
import PriceList from "../pages/PriceList/Routes";
import Rentabilidad from "../pages/Rentabilidad/Routes";
import RolOptions from "../pages/RolOptions/Routes";
import Roles from "../pages/Roles/Routes";
import UserType from "../pages/UserType/Routes";
import Venta from "../pages/Venta/Routes";
import ActualizarFormula from "../pages/actualizarFormula/Routes";
import Laboratorio from "../pages/Laboratorio/Routes";
import Colores from "../pages/colores/Routes";
import Precios from "../pages/precios/Routes";
import Sync from "../pages/sync/Routes";
import Usuarios from "../pages/usuarios/Routes";
const Unauthorized = Loadable(
  lazy(() => import("../pages/unauthorized/unauthorized"))
);
const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "not-authorized",
      element: <Unauthorized />,
    },
    ...Usuarios,
    ...Formulas,
    ...Libros,
    ...Contenido,
    ...ActualizarFormula,
    ...Laboratorio,
    ...Roles,
    ...FormulasBase,
    ...Opciones,
    ...RolOptions,
    ...Materiales,
    ...MaterialType,
    ...UserType,
    ...Precios,
    ...FormulasPigmentos,
    ...Venta,
    ...Colores,
    ...PriceList,
    ...Rentabilidad,
    ...Auditorias,
    ...Sync,
  ],
};

export default MainRoutes;
